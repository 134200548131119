<template>
  <div class="content">
    <div class="top_bg" style="background-image: url('img/qixi/top_bg.png')">
      <div
        class="back"
        style="background-image: url('img/qixi/back.png')"
        @click="goBack"
      ></div>
    </div>
    <div
      class="my_cp_bg"
      style="background-image: url('img/qixi/my_cp_bg.png');padding-top: 42px;"
    >   
        <div
            class="my_cp_item"
            @click="selectItem(item)"
            v-for="(item, index) in list"
            :key="index"
            :style="id==item.cp_id||item.is_display==1?'background-image: url('+'img/qixi/cp_bg_select.png'+')':'background-image: url('+'img/qixi/cp_bg_default.png'+')'"
          >
            <div class="cp_index">{{ index + 1 }}</div>
            <div class="mid" style="display: flex; align-items: center">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img class="cp_head" :src="item.a_head_portrait" alt="" />
                <div class="cp_nickname">{{ item.a_nickname }}</div>
              </div>
              <img class="heart" src="img/qixi/heart.png" alt="" />
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img class="cp_head" :src="item.b_head_portrait" alt="" />
                <div class="cp_nickname">{{ item.b_nickname }}</div>
              </div>
            </div>
            <div class="cp_vulue">甜蜜值：{{ item.inr_value }}</div>
          </div>
 
      
    </div>
    <div
      class="bottom_text"
      style="background-image: url('img/qixi/bottom_text.png')"
    ></div>
  </div>
</template>
<script>
import { myCpRank,selectCp } from "@/api/ranking";
import "@/assets/css/qixi.css";
export default {
  data() {
    return {
        list: [],
      searchData: {
        page: 1,
        rows: 20,
      },
      id:''
    };
  },
  created() {
    this.getRank();
  },
  mounted() {
    window.addEventListener("scroll", this.Scrollbottom); //页面加载时监听滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.Scrollbottom); //页面离开后销毁监听事件
  },
  methods: {
    getRank() {
        myCpRank(this.searchData).then((res) => {
        this.list =this.list.concat(res.data)
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    selectItem(item){
        this.id = item.cp_id
        selectCp({cp_id:item.cp_id}).then(res=>{
            this.$toast('展示成功')
            this.list=[]
            this.searchData.page=1
            this.id = ''
            this.getRank()
        })
    },
    Scrollbottom() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.searchData.page++;
        this.getRank();
        // console.log(this.pageNo);
        // this.fetchData()
      }
    },
  }
};
</script>
